<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import router from "./router";
export default {
  methods: {
    jump_collection() {
      router.push("/collection");
    },
    jump_user() {
      router.push("/user");
    },
  },
  created() {
    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
  },
};
</script>

<style>
#app {
  width: 100vw;
  overflow-y: hidden;
  height: 100vh;
}
* {
  margin: 0;
  padding: 0;
}
</style>
