<template>
  <div class="app">
    <header>
      {{ $store.state.title }}
      <span
        class="iconfont icon-jiahao"
        v-if="$store.state.title == '首页'"
        @click="addTask"
      ></span>
      <span
        class="iconfont icon-xiangzuo"
        v-if="$store.state.title == '操作' || $store.state.title == '新建任务'"
        style="color: #fff"
        @click="back"
      ></span>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <div class="tabbar">
        <div class="box" @click="jump('/collection', '首页')">
          <i class="iconfont icon-shouye"></i>
          <p>首页</p>
        </div>
        <div class="box" @click="jump('/user', '我的')">
          <i class="iconfont icon-yonghu"></i>
          <p>我的</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import router from "@/router";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["setTitle"]),
    addTask() {
      this.$router.push("/collection/addTask");
      this.$store.commit("setTitle", "新建任务");
    },
    back() {
      router.back();
      this.$store.commit("setTitle", "首页");
    },
    jump(value, name) {
      this.$router.push(value);
      this.$store.commit("setTitle", name);
    },
  },
};
</script>

<style lang="less" scoped>
.app,
main {
  height: 100%;
  width: 100%;
}
.app {
  display: flex;
  flex-direction: column;
}
main {
  flex-grow: 1;
  background-size: cover;
  background-color: #f7f9fa;
}
header {
  z-index: 500;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #ff7700;
  color: #fff;
}
footer {
  height: 50px;
}
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
}

.iconfont {
  font-size: 20px !important;
  color: #000;
}
.icon-xiangzuo {
  position: absolute;
  left: 10px;
}
.tabbar p {
  font-size: 16px;
  color: #666;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon-jiahao {
  position: absolute;
  right: 20px;
  color: #fff;
}
</style>
