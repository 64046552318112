import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/font/font_4337957_tifrxer0uze/iconfont.css";
import "./assets/global.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from "vue-baidu-map";

//index.vue

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(BaiduMap, {
  ak: "BOt3qCIcLpYORscXlddV4IFBfCG3e9Z7", //  在此输入你自己的百度地图ak
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
