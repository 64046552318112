import Vue from "vue";
import VueRouter from "vue-router";
import layout from "../layout/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/index.vue"),
  },
  {
    path: "/collection",
    component: layout,
    children: [
      {
        path: "/collection",
        component: () => import("../views/collection/index.vue"),
      },
    ],
  },
  {
    path: "/allot",
    name: "allot",
    component: layout,
    children: [
      {
        path: "/allot",
        component: () => import("../views/allot/index.vue"),
      },
    ],
  },
  {
    path: "/collection/detail",
    name: "collection-detail",
    component: layout,
    children: [
      {
        path: "/collection/detail",
        component: () => import("../views/collection/details/index.vue"),
      },
    ],
  },
  {
    path: "/collection/addTask",
    name: "collection-addTask",
    component: layout,
    children: [
      {
        path: "/collection/addTask",
        component: () => import("../views/collection/addTask/index.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: layout,
    children: [
      {
        path: "/user",
        component: () => import("../views/user/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
