import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "首页",
    account: 0,
    user: [],
  },
  getters: {},
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
